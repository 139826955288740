/* eslint-disable camelcase */
import { ExecuteResponse, PageResponse } from '@/util/models/ServerBaseEntity'
import { adaptPost, EmptyHttpError, execPostValid, getPost, queryPost } from '@/util/utils/HttpClient'
import { IValid, vr } from '@/util/utils/validator'

export interface GetUserTagResponse {
  vip1: boolean
  vip2: boolean
}
export interface CheckToolConfigRequest {
  tag: string
}
export interface GetUserConfigItem {
  selected: boolean
  name: string
  remark: string
}
export interface GetUserConfigResponse {
  configs: GetUserConfigItem[]
}
export interface QueryToolConfigRequest {
  page_no: number
  page_size: number
  tool_type: string
  config_name: string | undefined | null
}
export interface GetToolConfigRequest {
  tool_type: string
  id: number
  config_name: string | undefined | null
}
export interface GetToolConfigResponse {
  id: number
  tool_type: string
  context: string
  config_name: string
}
export interface QuerytToolConfigResponse {
  tool_type: string
  id: number
  config_name: string
  create_time: string
  modify_time: string
}
export class SaveToolConfigRequest extends IValid {
  id: number = 0

  @vr({ required: true, message: '配置类型不能为空' })
  tool_type: string = ''

  @vr({ required: true, message: '配置内容不能为空' })
  context: string = ''

  @vr({ required: true, message: '配置名称不能为空' })
  config_name: string = ''
}
export class ToolApi {
  @adaptPost<GetUserTagResponse>('/vscode/tool/getUserTag', s => s ?? { vip1: false, vip2: false })
  static async getUserTag(request: {}): Promise<GetUserTagResponse> {
    throw new EmptyHttpError()
  }

  @adaptPost<GetUserConfigResponse>('/vscode/tool/getUserConfig', s => s ?? { configs: [] })
  static async getUserConfig(request: {}): Promise<GetUserConfigResponse> {
    throw new EmptyHttpError()
  }

  @queryPost<QuerytToolConfigResponse>('/vscode/tool/config/query')
  static async queryToolConfig(request: QueryToolConfigRequest): Promise<PageResponse<QuerytToolConfigResponse>> {
    throw new EmptyHttpError()
  }

  @adaptPost<GetToolConfigResponse>('/vscode/tool/config/get', s => s ?? {})
  static async getToolConfig(request: GetToolConfigRequest): Promise<GetToolConfigResponse> {
    throw new EmptyHttpError()
  }

  @adaptPost<ExecuteResponse>('/vscode/tool/checkUserTag', s => s ?? { success: false })
  static async checkUserTag(request: CheckToolConfigRequest): Promise<ExecuteResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/vscode/tool/config/save')
  static async saveToolConfig(request: SaveToolConfigRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }
}
